import * as React from 'react'
import { Box, Center, Heading } from '@chakra-ui/react';

function Door(props) {
    const door = props.door;
    const bgColor = door.open ? 'door.open' : 'door.closed';
    const bgThumbnailImg = `url("http://img.youtube.com/vi/${door.vidId}/0.jpg")`;
    const borderRight = door.open ? '1px solid #cc2727' : '1px dashed rgba(158, 5, 5, 0.5)';
    const borderBottom = door.open ? '1px solid #cc2727' : '1px dashed rgba(158, 5, 5, 0.5)';
    const borderTop = door.open ? '1px solid #cc2727' : '1px dashed rgba(158, 5, 5, 0.5)';
    const borderLeft = door.open ? '1px solid #cc2727' : '1px dotted rgba(219, 24, 24, 0.3)';

    const handleOnClick = () => {
        props.doorClicked(door.id);
    }
    
    return (
        <Box 
            bg={door.open ? bgThumbnailImg : bgColor} 
            bgSize={"cover"}
            width="full" 
            marginBottom={3} 
            marginRight={3}
            borderRight={borderRight}
            borderLeft={borderLeft}
            borderBottom={borderBottom}
            borderTop={borderTop}
            borderTopRightRadius={10}
            borderBottomRightRadius={10}
            minHeight={{ sm: '200px', md: '140px', lg: '100px'}}
            style={{cursor:"pointer"}}
            onClick={handleOnClick}
        >
            <Center minHeight={{ sm: '200px', md: '140px', lg: '100px'}}>
                <Heading style={{marginBottom: 10, fontFamily: "cursive", fontSize: 30, color: "rgb(255, 200, 200)", textShadow: "1px 2px rgb(50, 50, 50)"}} as="h1">{door.value}</Heading>
            </Center>
        </Box>
    );
}

export default Door;
