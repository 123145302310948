const calendarYear = 2023;
const lsKey = `xmascal${calendarYear}-mk7`;
const vids2020 = ["HJbAHjA7nPI","du-TY1GUFGk","BW1aX0IbZOE","Wn4Kxy6bPI4","9UsRtnVXn6U","HHFFdy2NSdE","G7RgN9ijwE4","7zpxgyG7eGk","Oct2xKMGOno","oawUi9s3ENE","IdoD2147Fik","lKMDvCf1IUE","XLj9QtidiTo","dEI7oX0XxJw","Fuz26mUN2m0","WZy02_OFErk","71tBoyCs-Nc","qblUCI20v_0","B8uBR7nHgls","Rvp0wkUerAg","TiC8pig6PGE","IdyXKJ8NcNI","24e8Y3Vi0Ao","EKqIBDHnqHE"];
const vids = ["dhbjsmtz0oU","0XvnWxnivS8","Y4Z7Ds_yv8o","M3j2_vLBzPk","IdYBt4_QiHo","PaFnO5LKTSs","dvyZfa9x3UU","wgTZUU4gJ2U","gCyrKJg-1VM","tYpLDJfG05A","boDSEeGt8hg","enB2QL9Ulus","xCO_SUUMp6A","AnqPfbb0GlA","CIdXPIN3j38","d6gBu2Zd7Bc","lW4ugw_6w-Q","uB0mO42KM6g","LjBUZ43bLjE","9dd_L7gL5ms","yiOqHOgWEEM","lczsxfhh3po","xy1Mihmc_4c","EKqIBDHnqHE"];
const vids2023 = ["dhbjsmtz0oU","kkwiQmGWK4c","KCzwyFHSMdY","7XNlgwUNKjs","hpigjnKl7nI","blpe_sGnnP4","Fc1P-AEaEp8","CdIT74L8hGI","XCPj4JPbKtA","-w-58hQ9dLk","isP5srWOz0c","MiLszBaY7IU","ctBU8km42zA","zBJU9ndpH1Q","KZ6rJ-ra8zg","Ip7QZPw04Ks","5MRD273dz_8","G7RgN9ijwE4","vxuZuXPouqM","f5-wECt6A6o","OuSdU8tbcHY","znW8Zf6jvGg","VTO5yiN1b-I","EKqIBDHnqHE"];
const texts = [
    "December 1st. It's finally here!",
    "2",
    "December 3rd. Today is the first advent sunday.",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "December 10th. Today is the second advent sunday.",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "December 17th. Today is the third advent sunday.",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "December 24th. Today's the day! Oh and it's also the fourth advent sunday. Merry christmas everyone <3",
]

const loadData = () => {
    var data = localStorage.getItem(lsKey);
    if(data && data.length > 0) {
        return JSON.parse(data);
    } else {
        return null;
    }
}

const saveData = (data) => {
    var dataStr = JSON.stringify(data);
    localStorage.setItem(lsKey, dataStr);
}

// Fisher-Yates shuffle
const shuffle = (array) => {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
}

const createNewData = () => {
    var doorData = {
        doors: []
    };
    for(let i = 0; i < 24; i++) {
        var door = {
            id: `door-${i}`,
            value: i+1,
            displayValue: `Door ${i+1}`,
            openableOn: new Date(calendarYear,11,i+1),
            text: texts[i],
            open: false,
            vidId: vids2023[i],
        };
        doorData.doors.push(door);
    }
    //shuffle the doors around
    doorData.doors = shuffle(doorData.doors);
    return doorData;
}

const canOpenDoor = (door) => {
    return typeof door.openableOn === 'string' ? new Date() >= Date.parse(door.openableOn) : new Date() >= door.openableOn;
}

const getDoorData = () => {
    var data = loadData();
    if(data) return data;
    data = createNewData();
    saveData(data);
}

export {
    getDoorData,
    canOpenDoor,
    saveData
}
