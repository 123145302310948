import * as React from 'react'

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Homepage from './pages/homepage';

const theme = extendTheme({
  breakpoints: {
    sm: '320px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
  },
  size: {
    doorSize: {
      100: '100px',
      md: '100px',
      lg: '150px',
      xl: '200px',
    }
  },
  colors: {
    door: {
      open: '#d12d2d33',
      closed: 'tomato'
    }
  },
  styles: {
    global: (props) => ({
      body: {
        bg: "#00bd19",
        backgroundImage: "url('decoration-cornor.png')"
      }
    })
  }
})

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Homepage />
    </ChakraProvider>
  );
}

export default App;
