import * as React from 'react'
import { Stack, Box, SimpleGrid, GridItem, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, useDisclosure, Text } from '@chakra-ui/react';
import {useState} from 'react'

import { getDoorData, canOpenDoor, saveData } from '../../utils/utils';
import Door from '../door/door';

function Calendar() {
    const doorData = getDoorData();
    const [ currentDoor, setCurrentDoor ] = useState();
    const { isOpen, onOpen, onClose } = useDisclosure();
    

    const doorClicked = (doorId) => {
        for(var i = 0; i < doorData.doors.length; i++) {
            if(doorData.doors[i].id === doorId) {
                if(canOpenDoor(doorData.doors[i])) {
                    doorData.doors[i].open = true;
                    saveData(doorData);
                }
                setCurrentDoor(doorData.doors[i]);
                onOpen();
                break;
            }
        }
    }

    return (
        <Stack w="full" h="full" bg="tomato" padding={5} sx={{ borderRadius: "25" }} boxShadow="5px 5px 5px #384039">
            <Box w="full" h="full">
            </Box>
            <SimpleGrid columns={{base: 2, md: 3, lg: 4}} spacing={3}>
                {doorData.doors && doorData.doors.map((door) => 
                    <GridItem colSpan={1} key={door.id}>
                        <Door door={door} doorClicked={doorClicked} />
                    </GridItem>
                )}
            </SimpleGrid>
            <Modal isOpen={isOpen} onClose={onClose} size="half">
                <ModalOverlay />
                <ModalContent>
                    {currentDoor && canOpenDoor(currentDoor) ? (
                        <>
                            <ModalHeader>Christmas day {currentDoor && currentDoor.value}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Text>{currentDoor && currentDoor.text}</Text>
                                {currentDoor && currentDoor.vidId && (
                                    <iframe width="100%" height="100%" style={{minHeight: "500px"}} src={"https://www.youtube.com/embed/" + currentDoor.vidId} autoPlay="1"></iframe>
                                )}
                            </ModalBody>
                        </>
                    ) : (
                        <>
                            <ModalHeader>No sneak peaks!</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Text>
                                    You're not allowed to view this door before December {currentDoor && new Date(currentDoor.openableOn).getDate()}<br/>
                                    So here's a snowhater instead!
                                </Text>
                                <iframe width="100%" height="100%" style={{minHeight: "500px"}} src={"https://www.youtube.com/embed/l9bLoheF3uc?start=10&autoplay=true"} autoPlay="1"></iframe>
                            </ModalBody>
                        </>
                    )
                    }
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Stack>
    )
}

export default Calendar