import * as React from 'react'

import { Container, Heading, Center} from '@chakra-ui/react';

import Calendar from '../../components/calendar';

function Homepage() {
  return (
    <Container width="full">
      <Center>
        <Heading style={{marginBottom: 10, fontFamily: "cursive", fontSize: 40, color: "red", textShadow: "1px 2px black"}} as="h1">Den store julekalender</Heading>
      </Center>
      <Center>
        <Heading style={{marginBottom: 20, fontFamily: "cursive", fontSize: 20, color: "rgb(76, 105, 238)", textShadow: "1px 2px black"}} as="h2">Ombygget i React med Chakra UI!</Heading>
      </Center>
      <Calendar />
    </Container>
  );
}

export default Homepage;
